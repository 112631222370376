import { useEffect, useState, useRef } from "react";
import { z } from "zod";
import {
  deleteRecommendSchema,
  postOpenaiSchema,
  postRecommendSchema,
  recommendSchema,
} from "./schema";
import { Recommend } from "../../types/types";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../feature/auth/provider/AuthProvider";
import { useMutation } from "@tanstack/react-query";

import { toast } from "react-toastify";
import { auth, db } from "../../lib/firebase/firebase";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Timestamp } from "firebase/firestore";
import {
  deleteRecommendMutate,
  postOpenAI,
  postRecommendMutate,
} from "../../api/Recommend/Recommend";
import { AuthGuard } from "../../feature/auth/component/AuthGuard/AuthGuard";
import { signOut } from "firebase/auth";
import Chat from "./Chat/Chat";
import {
  calculateResult,
  containsValidJson,
  extractJsonValues,
  getPlanDetails,
  templateAnswer,
} from "../../utils";
export type FormValues = z.infer<typeof recommendSchema>;
export type postRecommendValue = z.infer<typeof postRecommendSchema>;
export type deleteRecommendValue = z.infer<typeof deleteRecommendSchema>;
export type postOpenAIValue = z.infer<typeof postOpenaiSchema>;
const RecommendPage = () => {
  const [messages, setMessages] = useState<Recommend[]>([]);
  const [accessToken, setAccessToken] = useState<string>("");
  const [disabledFlg, setDisabledFlg] = useState<boolean>(false);
  const [deleteFlg, setDeleteFlg] = useState<boolean>(false);
  const [finishFlg, setFinishFlg] = useState<boolean>(false);
  const navigation = useNavigate();
  const { user } = useAuthContext();
  const scrollRef = useRef<HTMLDivElement>(null);

  const mutation = useMutation<string, Error, postOpenAIValue>({
    mutationFn: (data) => {
      if (!accessToken) {
        throw new Error("Access token is undefined");
      }
      return postOpenAI(data.question, accessToken, user?.uid);
    },
  });

  const recommendMutation = useMutation<string, Error, postRecommendValue>({
    mutationFn: (data) => {
      if (!accessToken && !user?.uid) {
        throw new Error("Access token is undefined");
      }
      return postRecommendMutate(
        data.text || "最適なプランの診断をお願いします",
        accessToken,
        user?.uid,
        data.sender,
        data.status
      );
    },
  });

  const deleteRecommendsMutation = useMutation<
    string,
    Error,
    deleteRecommendValue
  >({
    mutationFn: () => {
      if (!accessToken) {
        throw new Error("Access token is undefined");
      }
      if (!user?.uid) {
        throw new Error("User UID is undefined");
      }
      return deleteRecommendMutate(accessToken, user.uid);
    },
  });
  const handleDelete = async () => {
    await deleteRecommendsMutation.mutateAsync({
      accessToken: accessToken,
      uid: user?.uid,
    });
  };
  useEffect(() => {
    const fetchToken = async () => {
      if (user) {
        const token = await user.getIdToken();
        setAccessToken(token);
      }
    };

    fetchToken();

    if (user) {
      const unsubscribe = db
        .collection("recommends")
        .where("uid", "==", user.uid)
        .orderBy("createdAt", "asc")
        .onSnapshot((snapshot) => {
          const recommendsData: Recommend[] = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              text: data.text,
              sender: data.sender,
              status: data.status,
            };
          });
          setMessages(recommendsData);
          const hasFinished = recommendsData.some(
            (recommend) => recommend.status === "finish"
          );

          setFinishFlg(hasFinished);
        });

      return () => unsubscribe();
    }
  }, [user]);

  const { register, handleSubmit, reset, setValue } = useForm<FormValues>({
    resolver: zodResolver(recommendSchema),
  });
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setDisabledFlg(true);

    try {
      await db.collection("recommends").add({
        text: data.question,
        uid: user?.uid,
        sender: "user",
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        status: "continuation",
      });

      const response = await mutation.mutateAsync({
        accessToken: accessToken,
        question: data.question,
      });

      const checkIncludeJson = containsValidJson(response);

      if (checkIncludeJson) {
        const responseJson = extractJsonValues(response);

        const getId = calculateResult(responseJson);

        const getDetails = getPlanDetails(getId);

        getDetails["debug"] = getId;
        const result = templateAnswer(getDetails);

        await recommendMutation.mutateAsync({
          text: result,
          accessToken: accessToken,
          uid: user?.uid,
          sender: "bot",
          status: "finish",
        });
      } else {
        await recommendMutation.mutateAsync({
          text: response,
          accessToken: accessToken,
          uid: user?.uid,
          sender: "bot",
          status: "continuation",
        });
      }
    } catch (error) {
      setDisabledFlg(false);
      console.error("Firestoreへの保存中にエラーが発生しました", error);
      toast.error("データの保存に失敗しました。");
      return;
    }

    setDisabledFlg(false);
    reset();
  };
  const handleSignOut = async () => {
    const confirm = window.confirm(
      "ログアウトします。\n本当によろしいですか？"
    );
    if (confirm) {
      try {
        await signOut(auth);
        toast.success("ログアウトしました。");
        navigation("/");
      } catch (e) {
        toast.error("ログアウトに失敗しました。");
      }
    }
  };
  const handleQuestionSubmit = (question: string | null) => {
    setDisabledFlg(true);
    setValue("question", question);

    handleSubmit(onSubmit)();
    setDisabledFlg(false);
    reset();
  };

  const onDeleteInit: SubmitHandler<FormValues> = async (data) => {
    setDeleteFlg(true);
    await handleDelete();
    setDeleteFlg(false);
    try {
      await db.collection("recommends").add({
        text: `最適な回線プランの診断を開始します。４つの質問の回答が終わったら結果を表示します。　最初の質問です。いま住んでいるのは？
               《##1. 戸建て住宅##》
               《##2. 集合住宅##》
               どちらにお住まいですか？
               `,
        uid: user?.uid,
        sender: "bot",
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      });
    } catch (error) {
      setDisabledFlg(false);
      console.error("Firestoreへの保存中にエラーが発生しました", error);
      toast.error("データの保存に失敗しました。");
      return;
    }

    setDisabledFlg(false);
    reset();
  };

  return (
    <AuthGuard>
      <div className="h-screen w-full flex flex-col font-MPLUS1p">
        <div className="fixed left-0 top-0 w-full z-20">
          <div className="bg-bgBlue flex items-center md:h-20 gap-[0.625rem] p-2 md:p-4">
            <div
              onClick={handleSignOut}
              className="bg-white rounded-full flex items-center justify-center p-2"
            >
              <img src="home.png" alt="ホームアイコン" className="w-10" />
            </div>
            <h1 className="flex font-extrabold text-white text-[1.5rem] leading-none md:pl-6">
              さすガねっと&nbsp;&nbsp;&nbsp;最適プラン レコメンド
            </h1>
          </div>
          <div className="bg-bgGray flex flex-col-reverse md:flex-row md:h-[5.315rem] gap-6 items-center justify-between p-4">
            <form onSubmit={handleSubmit(onDeleteInit)}>
              <input {...register("question")} type="text" hidden />
              <button
                type="submit"
                className={
                  deleteFlg
                    ? "block w-[93vw] md:w-[50vw] h-14 rounded-lg bg-orange text-white text-xl font-extrabold shadow-md px-6 py-2 grayscale"
                    : "block w-[93vw] md:w-[50vw] h-14 rounded-lg bg-orange text-white text-xl font-extrabold shadow-md px-6 py-2"
                }
              >
                回線プラン診断を始める
              </button>
            </form>
            <Link
              to="/home"
              className="block rounded-lg bg-white shadow-md w-[93vw] md:w-[45vw] p-2 px-4 flex items-center justify-center gap-2.5 h-14"
            >
              <img src="botIcon.png" alt="AIボットアイコン" />
              <span className="text-orange text-xl font-extrabold">
                サービス説明 AIボット
              </span>
            </Link>
          </div>
        </div>

        <div className="relative mt-[165px] pb-[125px] bg-bgAllGray flex-1">
          {deleteFlg && (
            <div className="fixed w-full h-screen mt-[-105px] flex justify-center items-center bg-bgAllGray bg-opacity-70 text-align z-10">
              <p className="text-xl font-extrabold">履歴を削除中……</p>
            </div>
          )}
          <Chat
            messages={messages.map((msg) => ({
              sender: msg.sender || "",
              text: msg.text,
            }))}
            onQuestionSubmit={handleQuestionSubmit}
            finishFlg={finishFlg}
          />

          {disabledFlg && (
            <div className="flex bg-questionBg items-start p-4 border-b border-borderGray shadow">
              <div>
                <img
                  src="chatgpt.png"
                  alt="OpenAIアイコン"
                  className="w-[52px]"
                />
              </div>
              <div className="flex-1 text-lg font-medium text-borderGray pl-5 py-3">
                AI回答中……
              </div>
            </div>
          )}
          <div ref={scrollRef} />
        </div>

        {messages.length > 0 && !finishFlg && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="fixed left-0 bottom-0 w-full"
          >
            <div className="p-4 bg-white border-t border-borderGray">
              <div className="relative w-full">
                <input
                  type="text"
                  {...register("question")}
                  placeholder="選択肢のボタンを押すか、回答を入力してください。"
                  className={
                    disabledFlg
                      ? "bg-bgGray px-4 py-6 w-full shadow-[0px_1px_7px_3px_rgba(0,0,0,0.2)] rounded-lg appearance-none resize-none outline-none"
                      : "bg-white px-4 py-6 w-full shadow-[0px_1px_7px_3px_rgba(0,0,0,0.2)] rounded-lg appearance-none resize-none outline-none"
                  }
                  required
                  disabled={disabledFlg}
                />
                <button
                  type="submit"
                  className={
                    disabledFlg
                      ? "absolute inset-y-0 right-4 w-[52px] grayscale"
                      : "absolute inset-y-0 right-4 w-[52px]"
                  }
                  disabled={disabledFlg}
                >
                  <img src="plane.png" alt="送信アイコン" />
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </AuthGuard>
  );
};

export default RecommendPage;
