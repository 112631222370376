import { useUsers } from "../../api/Admin/AdminGetUser";
import UseGetAdminToken from "../../hooks/UseGetAdminToken";
import {
  DataGrid,
  GridColDef,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { z } from "zod";
import { userDeleteSchema } from "./schema";
import { useMutation } from "@tanstack/react-query";
import { DeleteMutate } from "../../api/Admin/AdminDelete";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { MyAppBar } from "../../component/MyAppBar";

export type postValues = z.infer<typeof userDeleteSchema>;
const AdminHome = () => {
  UseGetAdminToken();

  const [userIds, setUserIds] = useState([]);

  const mutation = useMutation<string, Error, postValues>({
    mutationFn: async (data) => {
      const response = await DeleteMutate(data.userIds);
      return response;
    },
    onSuccess: () => {
      setUserIds([]);

      window.location.reload();
    },
    onError: (error) => {
      console.error("削除中にエラーが発生しました:", error);
    },
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Button onClick={deleteSelectedUsers}>選択したユーザーを削除</Button>
        <GridToolbarExport
          csvOptions={{ fileName: "users_data", utf8WithBom: true }}
        />
      </GridToolbarContainer>
    );
  }

  const handleSelectionChange = (params: any) => {
    setUserIds(params);
  };

  const deleteSelectedUsers = () => {
    if (userIds.length > 0) {
      if (window.confirm("選択したユーザーを削除しますか？")) {
        mutation.mutate({ userIds: userIds });
      }
    } else {
      toast.info("削除するユーザーを選択してください。");
    }
  };

  const columns: GridColDef[] = [
    { field: "companyName", headerName: "企業名", flex: 1 },
    { field: "userName", headerName: "氏名", flex: 1 },
    { field: "email", headerName: "メールアドレス", flex: 1 },
    { field: "questionCount", headerName: "質問回数", flex: 1 },
  ];

  const { data: users, isLoading, error } = useUsers();

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="mt-[72px]">
      <MyAppBar title="ユーザー管理" />
      <div className="flex flex-1">
        <aside
          aria-controls="menu"
          id="menu"
          className="fixed top-[72px] left-0 hidden md:block md:w-[240px]"
        >
          <div className="flex flex-col font-semibold my-3">
            <Link
              to="/admin/home"
              className="flex items-center gap-3 p-2 bg-bgBlue text-white"
            >
              <FontAwesomeIcon icon={faUser} />
              <span>ユーザー管理</span>
            </Link>
            <Link
              to="/admin/index"
              className="flex items-center gap-3 p-2 bg-white hover:bg-bgAllGray text-adminLink"
            >
              <FontAwesomeIcon icon={faDatabase} />
              <span>参照ファイル管理</span>
            </Link>
          </div>
        </aside>
        <main className="w-full ml-0 md:ml-[240px] p-4">
          <DataGrid
            rows={users}
            columns={columns}
            getRowId={(row) => row.id.replace(/\s+/g, "_")}
            onRowSelectionModelChange={handleSelectionChange}
            slots={{ toolbar: CustomToolbar }}
            checkboxSelection
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
            }}
          />
        </main>
      </div>
    </div>
  );
};
export default AdminHome;
