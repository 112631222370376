import { z } from "zod";

export const loginSchema = z.object({
  email: z
    .string({ invalid_type_error: "入力された形式が正しくありません" })
    .min(1, { message: "必須項目です" })
    .email({ message: "メールアドレスの形式で入力してください" }),
  password: z
    .string({ invalid_type_error: "入力された形式が正しくありません" })
    .min(1, { message: "必須項目です" }),
});
