import axios from "axios";

export const postOpenAI = async (
  question: string | null,
  accessToken: string,
  uid: string | undefined
) => {
  const response = await axios.post(
    "https://us-central1-chatbot-app-osakagas.cloudfunctions.net/api/openai",
    { question, uid },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
};

export const postRecommendMutate = async (
  text: string | null,
  accessToken: string,
  uid: string | undefined,
  sender: string,
  status: string
) => {
  const response = await axios.post(
    "https://us-central1-chatbot-app-osakagas.cloudfunctions.net/api/recommend",
    { text, uid, sender, status },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
};

export const deleteRecommendMutate = async (
  accessToken: string,
  uid: string | undefined
) => {
  const response = await axios.post(
    "https://us-central1-chatbot-app-osakagas.cloudfunctions.net/api/recommend/delete",
    { uid },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};
