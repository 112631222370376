import { useEffect, useState } from "react";
import { passwordResetSchema, postPasswordResetSchema } from "./schema";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import AuthLoadingButton from "../../component/atoms/LoadingButton/AuthLoadingButton";
import AuthButton from "../../component/atoms/Button/AuthButton";
import { useMutation } from "@tanstack/react-query";
import { PasswordResetMutate } from "../../api/Admin/AdminPasswordReset";
import { useNavigate } from "react-router-dom";

export type FormValues = z.infer<typeof passwordResetSchema>;
export type postValues = z.infer<typeof postPasswordResetSchema>;

const AdminResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const navigation = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const value = queryParams.get("token");

    if (value) {
      setToken(value);
    }
  }, []);
  const mutation = useMutation<string, Error, postValues>({
    mutationFn: async (data) => {
      return await PasswordResetMutate(data.password, token);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(passwordResetSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsLoading(true);

      await mutation.mutateAsync({ password: data.password, token });
      toast.success("パスワードを再設定しました。");

      setIsLoading(false);
      navigation("/admin/login");
      reset();
    } catch (e) {
      toast.error("パスワードの更新に失敗しました。");
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="min-h-screen flex justify-center items-center bg-gray-100 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md bg-white p-8 shadow-md">
          <h1 className="text-2xl font-bold mb-4 text-center">
            管理者 パスワード再設定
          </h1>
          <p>新しいパスワードを入力してください。</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-4">
              <input
                {...register("password")}
                type="password"
                placeholder="パスワード"
                className="w-full p-2 border rounded-md"
              />
              {errors.password && (
                <p className="text-errorText">{errors.password.message}</p>
              )}
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full pt-2 rounded-md hover:bg-green-600"
              >
                {isLoading ? (
                  <AuthLoadingButton />
                ) : (
                  <AuthButton content="送信" />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminResetPassword;
