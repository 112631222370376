import { z } from "zod";

export const registerSchema = z.object({
  companyName: z
    .string({ invalid_type_error: "入力された形式が正しくありません" })
    .min(1, { message: "必須項目です" }),
  email: z
    .string({ invalid_type_error: "入力された形式が正しくありません" })
    .min(1, { message: "必須項目です" })
    .email({ message: "メールアドレスの形式で入力してください" }),
  userName: z
    .string({ invalid_type_error: "入力された形式が正しくありません" })
    .min(1, { message: "必須項目です" })
    .max(20, { message: "20文字以内で入力してください" }),
  password: z
    .string({ invalid_type_error: "入力された形式が正しくありません" })
    .min(1, { message: "必須項目です" })
    .refine((password) => password.length >= 7, {
      message: "パスワードは7文字以上である必要があります",
    }),
});

export const postUserSchema = z.object({
  uid: z.string(),
  email: z.string(),
  userName: z.string(),
  companyName: z.string(),
  questionCount: z.number(),
});
