import { z } from "zod";

export const recommendSchema = z.object({
  question: z.string().nullable(),
});

export const postRecommendSchema = z.object({
  uid: z.string().optional(),
  text: z.string().nullable(),
  accessToken: z.string(),
  sender: z.string(),
  status: z.string(),
});

export const deleteRecommendSchema = z.object({
  uid: z.string().optional(),
  accessToken: z.string(),
});
export const postOpenaiSchema = z.object({
  accessToken: z.string(),
  question: z.string().nullable(),
});
