import { useState } from "react";

import { passwordResetSchema } from "./schema";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { auth } from "../../lib/firebase/firebase";
export type FormValues = z.infer<typeof passwordResetSchema>;
const PasswordReset = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(passwordResetSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsLoading(true);

      await sendPasswordResetEmail(auth, data.email);
      toast.success("パスワードリセットのメールを送信しました。");
      setIsLoading(false);

      reset();
    } catch (e) {
      toast.error("パスワードリセットのメールの送信に失敗しました。");
      setIsLoading(false);
      console.log(e);
    }
  };
  return (
    <>
      <div className="min-h-screen flex justify-center items-center bg-bgBlue px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md bg-white p-8 shadow-[0px_1px_7px_3px_rgba(0,0,0,0.2)] rounded-lg">
          <h1 className="text-2xl font-bold mb-4 text-center">
            パスワード再設定
          </h1>
          <p>
            ユーザー登録時のメールアドレスを入力してください。
            パスワードを再設定するリンクをお送りいたします。
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-4">
              <input
                {...register("email")}
                type="email"
                placeholder="email"
                className="w-full p-2 border rounded-md"
              />
              {errors.email && (
                <p className="text-errorText">{errors.email.message}</p>
              )}
            </div>
            {isLoading ? (
              <button
                className="w-full h-14 p-2 px-4 rounded-lg bg-bgAllGray text-xl font-extrabold shadow-md"
              >
                  送信中…
              </button>
            ) : (
              <button
                type="submit"
                className="w-full h-14 p-2 px-4 rounded-lg bg-bgBlue text-white text-xl font-extrabold shadow-md hover:opacity-70"
              >
                  送信
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
