import axios from "axios";

const backendUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_DEVELOPMENT_URL
    : process.env.REACT_APP_BACKEND_PRODUCTION_URL;
export const postQuestion = async (question: string, accessToken: string) => {
  const response = await axios.post(
    `${backendUrl}/langchain`,
    { question },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data.text;
};
