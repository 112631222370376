import { z } from "zod";

export const passwordResetSchema = z.object({
  password: z
    .string({ invalid_type_error: "入力された形式が正しくありません" })
    .min(7, { message: "7文字以上で入力してください。" }),
});

export const postPasswordResetSchema = z.object({
  password: z.string(),
  token: z.string(),
});
