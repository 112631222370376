import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useGetAdminToken = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("admin_token");

    if (!token) {
      navigate("/admin/login");
    } else {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const exp = payload.exp;
      const currentTime = Math.floor(Date.now() / 1000);

      if (exp < currentTime) {
        navigate("/admin/login");
      }
    }
  }, [navigate]);
};

export default useGetAdminToken;
