import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginSchema } from "./schema";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import useQueryParams from "../../hooks/UseQueryParamas";
import PasswordNew from "../../component/PasswordNew/PasswordNew";

export type FormValues = z.infer<typeof loginSchema>;

const Login = () => {
  const verify = useQueryParams("mode", "oobCode");

  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsLoading(true);
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, data.email, data.password);
      toast.success("ログインに成功しました。");
      setIsLoading(false);
      navigation("/home");
      reset();
    } catch (e) {
      toast.error("ログインに失敗しました。");
      setIsLoading(false);
      console.log(e);
    }
  };

  if (verify.typeValue === "resetPassword") {
    return <PasswordNew oobValue={verify.oobValue!} />;
  }

  return (
    <>
      <div className="min-h-screen flex flex-col justify-center items-center bg-bgBlue px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md bg-white p-8 shadow-[0px_1px_7px_3px_rgba(0,0,0,0.2)] rounded-lg">
          <h1 className="text-2xl font-bold mb-4 text-center">
            さすガねっと<br/>レコメンドAI支援ツール
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-gray-600 mb-2"
                htmlFor="username"
              >
                メールアドレス
              </label>
              <input
                {...register("email")}
                type="email"
                placeholder="email"
                className="w-full p-2 border rounded-md"
              />
              {errors.email && (
                <p className="text-errorText">{errors.email.message}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-gray-600 mb-2"
                htmlFor="password"
              >
                パスワード
              </label>
              <input
                {...register("password")}
                type="password"
                placeholder="password"
                className="w-full p-2 border rounded-md"
              />
              {errors.password && (
                <p className="text-errorText">{errors.password.message}</p>
              )}
            </div>
            {isLoading ? (
              <button
                className="w-full h-14 p-2 px-4 rounded-lg bg-bgAllGray text-xl font-extrabold shadow-md"
              >
                  ログイン中…
              </button>
            ) : (
              <button
                type="submit"
                className="w-full h-14 p-2 px-4 rounded-lg bg-bgBlue text-white text-xl font-extrabold shadow-md hover:opacity-70"
              >
                  ログイン
              </button>
            )}
          </form>
          <div className="mt-6">
            <Link to={"/register"}>新規登録</Link>
          </div>
          <div className="mt-2">
            <Link to={"/reset-password"}>パスワードを忘れた</Link>
          </div>
        </div>
        <div className="w-full max-w-md bg-white mt-3 p-8 shadow-[0px_1px_7px_3px_rgba(0,0,0,0.2)] rounded-lg">
          <p>
            ※こちらは、ChatGPTを活用した販売支援用のAIチャットボットです。<br />
            ※「さすガねっと」のサービス情報を参照して、さまざまな質問に回答します。<br />
            ※うまく回答が出ない場合は、質問する言葉を少し変えて何度か試してみてください。<br />
            ※AIは不確かな情報を返す場合があります。重要事項は必ず公式情報を確認してください。
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
