import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../provider/AuthProvider";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
type Props = {
  children: ReactNode;
};

export const AuthGuard = ({ children }: Props) => {
  const { user } = useAuthContext();
  const navigation = useNavigate();

  if (typeof user === "undefined") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (user === null) {
    navigation("/");
    return null;
  }

  return <>{children}</>;
};
