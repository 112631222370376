import { FC, useState } from "react";
import { passwordNewSchema } from "./schema";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";

import { auth } from "../../lib/firebase/firebase";
export type FormValues = z.infer<typeof passwordNewSchema>;
type Props = {
  oobValue: string;
};
const PasswordNew: FC<Props> = ({ oobValue }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(passwordNewSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsLoading(true);
      await auth.confirmPasswordReset(oobValue, data.password);

      setIsLoading(false);
      reset();
      toast.success("パスワードリセットに成功しました。", {
        onClose: () => {
          window.location.href = "/";
        },
      });
    } catch (e) {
      toast.error("パスワードリセットに失敗しました。");
      setIsLoading(false);
      console.log(e);
    }
  };
  return (
    <>
      <div className="min-h-screen flex justify-center items-center bg-bgBlue px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md bg-white p-8 shadow-[0px_1px_7px_3px_rgba(0,0,0,0.2)] rounded-lg">
          <h1 className="text-2xl font-bold mb-4 text-center">
            パスワード再設定
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-gray-600 mb-2"
                htmlFor="password"
              >
                パスワード
              </label>
              <input
                {...register("password")}
                type="password"
                placeholder="password"
                className="w-full p-2 border rounded-md"
              />
              {errors.password && (
                <p className="text-errorText">{errors.password.message}</p>
              )}
            </div>
            {isLoading ? (
              <button
                className="w-full h-14 p-2 px-4 rounded-lg bg-bgAllGray text-xl font-extrabold shadow-md"
              >
                  送信中…
              </button>
            ) : (
              <button
                className="w-full h-14 p-2 px-4 rounded-lg bg-bgBlue text-white text-xl font-extrabold shadow-md hover:opacity-70"
              >
                  送信
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default PasswordNew;
