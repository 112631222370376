import { useState } from "react";

import { passwordReminderSchema } from "./schema";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { toast } from "react-toastify";

import AuthLoadingButton from "../../component/atoms/LoadingButton/AuthLoadingButton";
import AuthButton from "../../component/atoms/Button/AuthButton";
import { PasswordReminderMutate } from "../../api/Admin/AdminReminder";
import { useMutation } from "@tanstack/react-query";
export type FormValues = z.infer<typeof passwordReminderSchema>;
const AdminPasswordReminder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const mutation = useMutation<string, Error, string>({
    mutationFn: async (email) => {
      return await PasswordReminderMutate(email);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(passwordReminderSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsLoading(true);

      await mutation.mutateAsync(data.email);
      toast.success("パスワードリセットのメールを送信しました。");
      setIsLoading(false);

      reset();
    } catch (e) {
      toast.error("パスワードリセットのメールの送信に失敗しました。");
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="min-h-screen flex justify-center items-center bg-gray-100 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md bg-white p-8 shadow-md">
          <h1 className="text-2xl font-bold mb-4 text-center">
            管理者 パスワード再設定
          </h1>
          <p>
            メールアドレスを入力してください。
            パスワードを再設定するリンクをお送りいたします。
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-4">
              <input
                {...register("email")}
                type="email"
                placeholder="email"
                className="w-full p-2 border rounded-md"
              />
              {errors.email && (
                <p className="text-errorText">{errors.email.message}</p>
              )}
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full pt-2 rounded-md hover:bg-green-600"
              >
                {isLoading ? (
                  <AuthLoadingButton />
                ) : (
                  <AuthButton content="送信" />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminPasswordReminder;
