import axios from "axios";

export const postUserMutate = async (
  uid: string,
  email: string,
  userName: string,
  companyName: string,
  questionCount: number
) => {
  const response = await axios.post(
    "https://us-central1-chatbot-app-osakagas.cloudfunctions.net/api/user/create",
    { uid, email, userName, companyName, questionCount }
  );
  return response.data;
};
