import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "./pages/NotFound/NotFound";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import AdminHome from "./pages/AdminHome/AdminHome";
import Register from "./pages/Register/Register";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import { AuthProvider } from "./feature/auth/provider/AuthProvider";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import AdminPasswordReminder from "./pages/AdminPasswordReminder/AdminPasswordReminder";
import AdminResetPassword from "./pages/AdminResetPassword/AdminResetPassword";
import AdminIndex from "./pages/AdminIndex/AdminIndex";

import RecommendPage from "./pages/Recommend/Recommend";

import "./index.css";

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
      />

      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path={"/"} element={<Login />} />
            <Route path={"/home"} element={<Home />} />
            <Route path={"/recommend"} element={<RecommendPage />} />
            <Route path={"/register"} element={<Register />} />
            <Route path={"/login"} element={<Login />} />
            <Route path={"/reset-password"} element={<PasswordReset />} />

            <Route path={"/admin/login"} element={<AdminLogin />} />

            <Route
              path={"/admin/reminder"}
              element={<AdminPasswordReminder />}
            />
            <Route
              path={"/admin/reset-password"}
              element={<AdminResetPassword />}
            />
            <Route path="/admin/home/" element={<AdminHome />} />
            <Route path="/admin/index/" element={<AdminIndex />} />

            <Route path={"*"} element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
