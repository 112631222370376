import React, { FC, useEffect, useRef } from "react";
type Message = {
  sender: string | null;
  text: any;
};

type Props = {
  messages: Message[];
  onQuestionSubmit: (text: string) => void;
  finishFlg: boolean;
};

type QuestionTextProps = {
  text: string;
};

const Chat: FC<Props> = ({ messages, onQuestionSubmit, finishFlg }) => {
  const scrollDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // scrollDiv?.current?.scrollIntoView({ behavior: "smooth" });
    if (scrollDiv.current) {
      const answerScrollTop = scrollDiv.current.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          165;

      console.log(answerScrollTop);

      window.scrollTo({
        behavior: "smooth",
        top: answerScrollTop,
      });
    }

  }, [messages]);

  const handleButtonClick = (text: string) => {
    onQuestionSubmit(text);
  };
  const QuestionText = ({ text }: QuestionTextProps) => {
    const buttonRegex = /《##(\d\..*?)##》/g;
    const imgTagRegex =
      /<img\s+src="([^"]+)"\s+alt="([^"]+)"\s+className="([^"]+)"\s*\/?>/;
    const imgFileRegex = /([a-zA-Z0-9_-]+)\.(png|jpg|jpeg|gif)/;
    const urlRegex = /https?:\/\/[^\s]+/g;
    let match;
    let lastIndex = 0;
    const btnElements = [];
    const elements = [];

    const processSubstring = (substring: string, index: number) => {
      substring.split("\n").forEach((line, lineIndex) => {
        const imgTagMatch = line.match(imgTagRegex);
        const imgFileMatch = line.match(imgFileRegex);
        const urlMatch = line.match(urlRegex);

        if (imgTagMatch) {
          const src = imgTagMatch[1];
          const alt = imgTagMatch[2];
          const className = imgTagMatch[3];
          elements.push(
            <img
              src={src}
              alt={alt}
              className={className}
              key={`${index}-img-${lineIndex}`}
            />
          );
        } else if (imgFileMatch) {
          const imgSrc = imgFileMatch[0];
          elements.push(
            <img
              src={imgSrc}
              alt="画像"
              className="w-full"
              key={`${index}-file-img-${lineIndex}`}
            />
          );
        } else if (urlMatch) {
          const urlIndex = line.indexOf(urlMatch[0]);
          const textBeforeUrl = line.substring(0, urlIndex);
          const textAfterUrl = line.substring(urlIndex + urlMatch[0].length);

          // URLの前のテキストを追加
          if (textBeforeUrl) {
            elements.push(
              <p key={`${index}-text-before-url-${lineIndex}`}>
                {textBeforeUrl}
              </p>
            );
          }

          // URLをクリック可能なリンクとして追加
          elements.push(
            <a
              href={urlMatch[0]}
              target="_blank"
              rel="noopener noreferrer"
              key={`${index}-url-${lineIndex}`}
              className="underline"
            >
              {urlMatch[0]}
            </a>
          );

          if (textAfterUrl) {
            elements.push(
              <p key={`${index}-text-after-url-${lineIndex}`}>{textAfterUrl}</p>
            );
          }
        } else {
          elements.push(<p key={`${index}-span-${lineIndex}`}>{line}</p>);
        }
      });
    };

    while ((match = buttonRegex.exec(text)) !== null) {
      const beforeText = text.substring(lastIndex, match.index);
      const buttonText = match[1];

      if (match.index > lastIndex) {
        processSubstring(beforeText, lastIndex);
      }

      btnElements.push(
        <button
          className="inline-block w-[calc(50%_-_0.5rem)] md:w-[calc(33%_-_0.5rem)] bg-orange shadow-md text-white rounded m-1 px-4 py-6"
          key={match.index}
          onClick={() => handleButtonClick(buttonText)}
          disabled={finishFlg}
        >
          {buttonText}
        </button>
      );

      lastIndex = buttonRegex.lastIndex;
    }

    elements.push(btnElements);

    if (lastIndex < text.length) {
      processSubstring(text.substring(lastIndex), lastIndex);
    }

    return <div>{elements}</div>;
  };

  return (
    <div>
      {messages.map((message: Message, index: number) => (
        <React.Fragment key={index}>
          <div
            ref={scrollDiv}
            className={
              message.sender === "bot"
                ? "flex bg-questionBg items-start p-4 border-b border-borderGray shadow scroll-p-[165px]"
                : "flex bg-white items-start p-4 border-b border-borderGray shadow scroll-p-[165px]"
            }
          >
            <div>
              {message.sender === "bot" ? (
                <img
                  src="chatgpt.png"
                  alt="OpenAIアイコン"
                  className="w-[52px]"
                />
              ) : (
                <img
                  src="userIcon.png"
                  alt="ユーザアイコン"
                  className="w-[52px]"
                />
              )}
            </div>
            <div className="flex-1 text-lg font-medium pl-5 py-3 break-all">
              <QuestionText text={message.text} />
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Chat;
