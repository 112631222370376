import axios from "axios";

export const postServiceMutate = async (
  text: string,
  accessToken: string,
  uid: string | undefined,
  sender: string
) => {
  const response = await axios.post(
    "https://us-central1-chatbot-app-osakagas.cloudfunctions.net/api/service",
    { text, uid, sender },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
};

export const deleteServiceMutate = async (
  accessToken: string,
  uid: string | undefined
) => {
  const response = await axios.post(
    "https://us-central1-chatbot-app-osakagas.cloudfunctions.net/api/service/delete",
    { uid },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};
