import React from "react";
import ReactDOM from "react-dom/client";
import "../src/index.css";
import App from "./App";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "./lib/firebase/firebase";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
firebase.initializeApp(firebaseConfig);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);
