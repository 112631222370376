import { useEffect, useState } from "react";

export default function useQueryParams(mode: string, oobCode: string) {
  const [typeValue, setTypeValue] = useState<string | null>(null);
  const [oobValue, setOobValue] = useState<string | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const type = queryParams.get(mode);
    const value = queryParams.get(oobCode);

    setTypeValue(type !== null ? type : null);
    setOobValue(value !== null ? value : null);
  }, [mode, oobCode]);

  return { typeValue, oobValue };
}
