import { z } from "zod";

export const passwordNewSchema = z.object({
  password: z
    .string({ invalid_type_error: "入力された形式が正しくありません" })
    .min(1, { message: "必須項目です" })
    .refine((password) => password.length >= 7, {
      message: "パスワードは7文字以上である必要があります",
    }),
});
