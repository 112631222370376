import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/functions";

export const firebaseConfig = {
  apiKey: "AIzaSyCkScTnZmQV7IA52u3zHfkYYTDaKoApi-A",
  authDomain: "chatbot-app-osakagas.firebaseapp.com",
  projectId: "chatbot-app-osakagas",
  storageBucket: "chatbot-app-osakagas.appspot.com",
  messagingSenderId: "643219230720",
  appId: "1:643219230720:web:67d487729a547cac2088e5",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
