import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { postUserSchema, registerSchema } from "./schema";
import { toast } from "react-toastify";
import { z } from "zod";
import {
  sendEmailVerification,
  getAuth,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { postUserMutate } from "../../api/Auth/postCreateUser";

export type FormValues = z.infer<typeof registerSchema>;
export type postUserValue = z.infer<typeof postUserSchema>;

const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const questionCount = 0;
  const [uid, setUid] = useState<string>("");
  const mutation = useMutation<string, Error, postUserValue>({
    mutationFn: async (data) => {
      return await postUserMutate(
        uid,
        data.email,
        data.userName,
        data.companyName,
        questionCount
      );
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(registerSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsLoading(true);
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      setUid(userCredential.user.uid);
      await sendEmailVerification(userCredential.user);

      await mutation.mutateAsync({
        uid,
        email: data.email,
        userName: data.userName,
        companyName: data.companyName,
        questionCount,
      });
      toast.success("入力したアドレスに認証リンクを送信しました。");
      setIsLoading(false);
      reset();
    } catch (e) {
      toast.error("新規登録に失敗しました。");
      setIsLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <div className="min-h-screen flex justify-center items-center bg-bgBlue px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md bg-white p-8 shadow-[0px_1px_7px_3px_rgba(0,0,0,0.2)] rounded-lg">
          <h1 className="text-2xl font-bold mb-4 text-center">
            さすガねっと<br/>レコメンドAI支援ツール
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600 mb-2">
                会社名
              </label>
              <input
                {...register("companyName")}
                type="text"
                placeholder="企業名"
                className="w-full p-2 border rounded-md"
              />
              {errors.companyName && (
                <p className="text-errorText">{errors.companyName.message}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600 mb-2">
                氏名
              </label>
              <input
                {...register("userName")}
                type="text"
                placeholder="氏名"
                className="w-full p-2 border rounded-md"
              />
              {errors.userName && (
                <p className="text-errorText">{errors.userName.message}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600 mb-2">
                メールアドレス
              </label>
              <input
                {...register("email")}
                type="email"
                placeholder="メールアドレス"
                className="w-full p-2 border rounded-md"
              />
              {errors.email && (
                <p className="text-errorText">{errors.email.message}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600 mb-2">
                パスワード
              </label>
              <input
                {...register("password")}
                type="password"
                placeholder="パスワード"
                className="w-full p-2 border rounded-md"
              />
              {errors.password && (
                <p className="text-errorText">{errors.password.message}</p>
              )}
            </div>
            {isLoading ? (
              <button
                className="w-full h-14 p-2 px-4 rounded-lg bg-bgAllGray text-xl font-extrabold shadow-md"
              >
                  登録中…
              </button>
            ) : (
              <button
                type="submit"
                className="w-full h-14 p-2 px-4 rounded-lg bg-bgBlue text-white text-xl font-extrabold shadow-md hover:opacity-70"
              >
                  登録
              </button>
            )}
          </form>
          <div className="mt-6">
            <Link to={"/"} className="text-blue-300">
              すでにアカウントを持っている
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
