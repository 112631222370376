type PlanDetails = {
  recommendPlan: string;
  image1: string | null;
  image2: string | null;
  image3: string | null;
  defaultImage: string;
  description: string;
  alertDescription: string | null;
  debugSelect?: string;
  debug?: string;
  link: string;
};

// json判定
export function containsValidJson(text: string): boolean {
  try {
    // オブジェクトの場合、JSON 文字列に変換して検証します。
    if (typeof text === "object") {
      JSON.stringify(text);
    } else {
      JSON.parse(text);
    }
    return true;
  } catch (error) {
    const regexJsonTag = /```json\s*({[\s\S]*?})\s*```/;
    const regexBackticks = /```\s*({[\s\S]*?})\s*```/;
    let match = text.match(regexJsonTag) || text.match(regexBackticks);

    if (match) {
      try {
        JSON.parse(match[1]);
        return true;
      } catch (innerError) {
        return false;
      }
    } else {
      return false;
    }
  }
}

// json抽出
export function extractJsonValues(text: string): string {
  let data: any;

  // オブジェクトをJSON文字列に変換する処理
  const jsonString = typeof text === "object" ? JSON.stringify(text) : text;

  try {
    data = JSON.parse(jsonString);
  } catch (error) {
    try {
      const correctedText = jsonString.replace(/(\w+):/g, '"$1":');
      data = JSON.parse(correctedText);
    } catch (error) {
      const regex = /```(?:json)?\s*([\s\S]*?)\s*```/;

      const regexPlain = /```\s*([\s\S]*?)\s*```/;

      const match = jsonString.match(regex) || jsonString.match(regexPlain);
      if (match && match[1]) {
        try {
          data = JSON.parse(match[1]);
        } catch (error) {
          return "Invalid format or JSON not found.";
        }
      } else {
        return "Invalid format or JSON not found.";
      }
    }
  }

  // JSONオブジェクトからすべての値を取得し、それらを結合する
  if (typeof data === "object" && data !== null) {
    const values = Object.values(data).join("");
    return values;
  } else {
    return "Invalid format or JSON not found.";
  }
}

// 結果id取得
export function calculateResult(choice: string): string {
  const mapping: { [key: string]: string } = {
    "1111": "1110",
    "1112": "1100",
    "1121": "1111",
    "1122": "1101",
    "1131": "1100",
    "1132": "1100",
    "1213": "2100",
    "1214": "2200",
    "1223": "2101",
    "1224": "2101",
    "1233": "2100",
    "1234": "2200",
    "1415": "4410",
    "1416": "4100",
    "1425": "4411",
    "1426": "4101",
    "1435": "4410",
    "1436": "4100",
    "1525": "5101",
    "1526": "5101",
    "1535": "5100",
    "1536": "5100",
  };

  if (mapping[choice]) {
    return mapping[choice];
  }

  if (choice.startsWith("111") && "3" <= choice[3] && choice[3] <= "9") {
    return "1100";
  }
  if (choice.startsWith("112") && "3" <= choice[3] && choice[3] <= "9") {
    return "1101";
  }
  if (choice.startsWith("113") && "3" <= choice[3] && choice[3] <= "9") {
    return "1100";
  }
  if (
    choice.startsWith("121") &&
    ["1", "2", "5", "6", "7", "8", "9"].includes(choice[3])
  ) {
    return "2200";
  }
  if (
    choice.startsWith("122") &&
    ["1", "2", "5", "6", "7", "8", "9"].includes(choice[3])
  ) {
    return "2101";
  }
  if (
    choice.startsWith("123") &&
    ["1", "2", "5", "6", "7", "8", "9"].includes(choice[3])
  ) {
    return "2200";
  }
  if (
    choice.startsWith("141") &&
    ["1", "2", "3", "4", "7", "8", "9"].includes(choice[3])
  ) {
    return "4100";
  }
  if (
    choice.startsWith("142") &&
    ["1", "2", "3", "4", "7", "8", "9"].includes(choice[3])
  ) {
    return "4101";
  }
  if (
    choice.startsWith("143") &&
    ["1", "2", "3", "4", "7", "8", "9"].includes(choice[3])
  ) {
    return "4100";
  }
  if (
    choice.startsWith("152") &&
    ["1", "2", "3", "4", "7", "8", "9"].includes(choice[3])
  ) {
    return "5101";
  }
  if (
    choice.startsWith("153") &&
    ["1", "2", "3", "4", "7", "8", "9"].includes(choice[3])
  ) {
    return "5100";
  }
  if (choice.startsWith("161") && "1" <= choice[3] && choice[3] <= "9") {
    return "6200";
  }
  if (choice.startsWith("162") && "1" <= choice[3] && choice[3] <= "9") {
    return "6200";
  }
  if (choice.startsWith("163") && "1" <= choice[3] && choice[3] <= "9") {
    return "6200";
  }

  if (
    choice.startsWith("21") &&
    ["1", "2", "5", "6", "7", "8", "9"].includes(choice[2]) &&
    choice[3] === "1"
  ) {
    return "1200";
  }
  if (
    choice.startsWith("21") &&
    ["1", "2", "5", "6", "7", "8", "9"].includes(choice[2]) &&
    choice[3] === "2"
  ) {
    return "1200";
  }
  if (
    choice.startsWith("21") &&
    ["1", "2", "5", "6", "7", "8", "9"].includes(choice[2]) &&
    choice[3] === "9"
  ) {
    return "1200";
  }
  if (
    choice.startsWith("22") &&
    ["1", "2", "3"].includes(choice[2]) &&
    choice[3] === "3"
  ) {
    return "2200";
  }
  if (
    choice.startsWith("22") &&
    ["1", "2", "3"].includes(choice[2]) &&
    choice[3] === "4"
  ) {
    return "2200";
  }

  if (
    choice.startsWith("22") &&
    ["1", "2", "3"].includes(choice[2]) &&
    ["1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(choice[3])
  ) {
    return "2200";
  }
  if (
    choice.startsWith("23") &&
    ["1", "2", "3"].includes(choice[2]) &&
    choice[3] === "5"
  ) {
    return "3310";
  }
  if (
    choice.startsWith("23") &&
    ["1", "2", "3"].includes(choice[2]) &&
    choice[3] === "6"
  ) {
    return "3500";
  }
  if (
    choice.startsWith("23") &&
    ["1", "2", "3"].includes(choice[2]) &&
    ["1", "2", "3", "4", "7", "8", "9"].includes(choice[3])
  ) {
    return "3500";
  }
  if (
    choice.startsWith("24") &&
    ["1", "2", "3"].includes(choice[2]) &&
    choice[3] === "5"
  ) {
    return "4310";
  }
  if (
    choice.startsWith("24") &&
    ["1", "2", "3"].includes(choice[2]) &&
    choice[3] === "6"
  ) {
    return "4300";
  }
  if (
    choice.startsWith("24") &&
    ["1", "2", "3"].includes(choice[2]) &&
    ["1", "2", "3", "4", "7", "8", "9"].includes(choice[3])
  ) {
    return "4300";
  }
  if (
    choice.startsWith("25") &&
    ["1", "2", "3"].includes(choice[2]) &&
    choice[3] === "5"
  ) {
    return "5310";
  }
  if (
    choice.startsWith("25") &&
    ["1", "2", "3"].includes(choice[2]) &&
    choice[3] === "6"
  ) {
    return "5200";
  }
  if (
    choice.startsWith("25") &&
    ["1", "2", "3"].includes(choice[2]) &&
    ["1", "2", "3", "4", "7", "8", "9"].includes(choice[3])
  ) {
    return "5200";
  }

  if (
    choice.startsWith("26") &&
    ["1", "2", "3"].includes(choice[2]) &&
    ["1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(choice[3])
  ) {
    return "6200";
  }
  return "9999";
}

// 結果idからプラン詳細を取得
export function getPlanDetails(planCode: string): PlanDetails {
  const planDetails: { [key: string]: PlanDetails } = {
    "1100": {
      recommendPlan: "めちゃはやプラン",
      image1: "plan1.png",
      image2: "price11.png",
      image3: null,
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。さらに、駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※ホワイト光電話をご利用の場合、電話番号の引継ぎ時にアナログ戻しが必要です。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_2.html",
    },
    "1101": {
      recommendPlan: "めちゃはやプラン",
      image1: "plan1.png",
      image2: "price11.png",
      image3: "sp.png",
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。さらに、駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※ホワイト光電話をご利用の場合、電話番号の引継ぎ時にアナログ戻しが必要です。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_2.html",
    },
    "1110": {
      recommendPlan: "めちゃはやプラン",
      image1: "plan1.png",
      image2: "price11.png",
      image3: "antenna.png",
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！加えて、現在適用中のスマホセット割引(おうち割光セット)も継続可能です！また、違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※ホワイト光電話をご利用の場合、電話番号の引継ぎ時にアナログ戻しが必要です。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_2.html",
    },
    "1111": {
      recommendPlan: "めちゃはやプラン",
      image1: "plan1.png",
      image2: "price11.png",
      image3: "sp_antenna.png",
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！加えて、現在適用中のスマホセット割引(おうち割光セット)も継続可能です！アンテナキャッシュバックCPもご用意しておりますので、テレビご利用料金の見直しも合わせて実施いただけます。違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※ホワイト光電話をご利用の場合、電話番号の引継ぎ時にアナログ戻しが必要です。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_2.html",
    },
    "1200": {
      recommendPlan: "はやとくプラン",
      image1: "plan2.png",
      image2: "price12.png",
      image3: null,
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットより月額料金が安くなる可能性があります！事業者変更手続きで工事無しでご対応いただけます。違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※スマホセット割引は適用不可のため注意してください。※「月額が安くなる」はスタート割を含めた2年間の総額で算出しています。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_1.html",
    },
    "2100": {
      recommendPlan: "めちゃはやプラン",
      image1: "plan1.png",
      image2: "price21.png",
      image3: null,
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。さらに、駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※スマホセット割引は適用不可のため注意してください。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_2.html",
    },
    "2101": {
      recommendPlan: "めちゃはやプラン",
      image1: "plan1.png",
      image2: "price21.png",
      image3: "antenna.png",
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！さらに、アンテナキャッシュバックCPにより、テレビご利用料金の見直しも合わせて実施いただけます。違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。 ",
      alertDescription:
        "※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_2.html",
    },
    "2200": {
      recommendPlan: "はやとくプラン",
      image1: "plan2.png",
      image2: "price22.png",
      image3: null,
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットより月額料金が安くなる可能性があります！事業者変更手続きで工事無しでご対応いただけます。違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_1.html",
    },
    "3310": {
      recommendPlan: "とくとくプラン1G",
      image1: "plan3.png",
      image2: "price33.png",
      image3: "sp.png",
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも月額も安くなる可能性があります！加えて、現在適用中のスマホセット割引(auスマートバリュー)も継続可能です！また、違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_t1.html",
    },
    "3500": {
      recommendPlan: "とくとくプラン1G／はやとくプラン",
      image1: "plan5.png",
      image2: "price35.png",
      image3: null,
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも月額も安くなる可能性があります！また、違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/index.html",
    },
    "4100": {
      recommendPlan: "めちゃはやプラン",
      image1: "plan1.png",
      image2: "price41.png",
      image3: null,
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。さらに、駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※スマホセット割引は適用不可のため注意してください。※「月額が安くなる」はスタート割を含めた3年間の総額で算出しています。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_2.html",
    },
    "4101": {
      recommendPlan: "めちゃはやプラン",
      image1: "plan1.png",
      image2: "price41.png",
      image3: "antenna.png",
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！さらに、アンテナキャッシュバックCPにより、テレビご利用料金の見直しも合わせて実施いただけます。違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_2.html",
    },
    "4300": {
      recommendPlan: "とくとくプラン1G",
      image1: "plan3.png",
      image2: "price43.png",
      image3: null,
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも月額も安くなる可能性があります！また、違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※eo光はVDSLでの提供のケースを想定。※「月額が安くなる」はスタート割を含めた2年間で算出。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_t1.html",
    },
    "4310": {
      recommendPlan: "とくとくプラン1G",
      image1: "plan3.png",
      image2: "price43.png",
      image3: "sp.png",
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも月額も安くなる可能性があります！加えて、現在適用中のスマホセット割引(auスマートバリュー)も継続可能です！また、違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※eo光はVDSLでの提供のケースを想定。※「月額が安くなる」はスタート割を含めた2年間で算出。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_t1.html",
    },
    "4410": {
      recommendPlan: "とくとくプラン1G／めちゃはやプラン",
      image1: "plan4.png",
      image2: "price44.png",
      image3: "sp.png",
      defaultImage: "cp.png",
      description:
        "■とくとくプラン1Gの場合現在お使いのインターネットよりも月額も安くなる可能性があります！加えて、現在適用中のスマホセット割引(auスマートバリュー)も継続可能です！また、違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。■めちゃはやプランの場合現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。さらに、駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※めちゃはやプランの場合、スマホセット割は適用不可。「月額が安くなる」はスタート割を含めた3年間で算出。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/index.html",
    },
    "4411": {
      recommendPlan: "とくとくプラン1G／めちゃはやプラン",
      image1: "plan4.png",
      image2: "price44.png",
      image3: "sp_antenna.png",
      defaultImage: "cp.png",
      description:
        "■とくとくプラン1Gの場合現在お使いのインターネットよりも月額も安くなる可能性があります！加えて、現在適用中のスマホセット割引(auスマートバリュー)も継続可能です！また、違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。■めちゃはやプランの場合現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！さらに、アンテナキャッシュバックCPにより、テレビご利用料金の見直しも合わせて実施いただけます。違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※めちゃはやプランの場合、スマホセット割は適用不可。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/index.html",
    },
    "5100": {
      recommendPlan: "めちゃはやプラン",
      image1: "plan1.png",
      image2: "price51.png",
      image3: null,
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。さらに、駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※「月額が安くなる」はスタート割を含めた3年間で算出。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_2.html",
    },
    "5101": {
      recommendPlan: "めちゃはやプラン",
      image1: "plan1.png",
      image2: "price51.png",
      image3: "antenna.png",
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！さらに、アンテナキャッシュバックCPにより、テレビご利用料金の見直しも合わせて実施いただけます。違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※CSで視聴されているテレビ番組が無いかご注意してください。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_2.html",
    },
    "5200": {
      recommendPlan: "はやとくプラン",
      image1: "plan2.png",
      image2: "price52.png",
      image3: null,
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。さらに、駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※他社プランの速度は320Mで想定しています。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_1.html",
    },
    "5310": {
      recommendPlan: "とくとくプラン1G",
      image1: "plan3.png",
      image2: "price53.png",
      image3: "sp.png",
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットよりも速度が速くなり、月額も安くなる可能性があります！加えて、現在適用中のスマホセット割引(auスマートバリュー)も継続可能です！また、違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※JCOMNETからの切り替えに該当するため、違約金補填割引は適用不可となります。※他社プランの速度は320Mを想定。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_t1.html",
    },
    "6200": {
      recommendPlan: "はやとくプラン",
      image1: "plan2.png",
      image2: "price62.png",
      image3: null,
      defaultImage: "cp.png",
      description:
        "現在お使いのインターネットより月額料金が安くなる可能性があります！転用手続きで工事無しでご対応いただけます。違約金補填割引や工事費補填割引により、切り替え時負担も軽減いただけます。駆けつけサポートやスマホ・タブレットの修理保証も付いて、安心してご利いただけます。このタイミングでぜひ切り替えをご検討ください。",
      alertDescription:
        "※お客さまの宅内の状況によっては工事(機器の交換等)が発生するケースがあります。お客さまが使用されているWiFiルーターを確認の上、レンタルルーターの必要可否をご確認ください。※2024年1月時点の情報となりますので、ご提案時には必ずお客さまの契約情報をご確認ください。",
      link: "https://home.osakagas.co.jp/internet/plan/plan_1.html",
    },
    "9999": {
      recommendPlan: "該当プラン無し",
      image1: null,
      image2: null,
      image3: null,
      defaultImage: "cp.png",
      description:
        "該当するレコメンド情報がありません。選択肢ボタンで入力をして、再度お試しください。",
      alertDescription:
        "公式サイトから、各プランの詳細情報をご覧いただけます。",
      link: "https://home.osakagas.co.jp/internet/plan/index.html",
    },
  };

  return planDetails[planCode] || planDetails["9999"];
}

// 最終回答雛形
// TODO: デバッグ消す

export function templateAnswer(template: any) {
  const alertDescriptionText = template.alertDescription
    ? `【ご注意】 ${template.alertDescription}`
    : "";
  const sentenceLink = template.link
    ? `【公式サイトのプラン説明】 ${template.link}`
    : "";
  // image3が存在する場合のみ、対応するテキストを生成する
  const image1 = template.image1 ? template.image1 : "";
  const image2 = template.image2 ? template.image2 : "";
  const image3 = template.image3 ? template.image3 : "";

  return `

  結果ID：${template.debug}\n
  お客様におすすめのプランは『${template.recommendPlan}』です！\n
  ${template.description}\n
  ${alertDescriptionText}\n
  ${sentenceLink}\n
  プランの詳細、現在の回線との料金比較は、下記の図をご確認ください。\n
  ${image1}\n
  ${image2}\n
  ${image3}\n
  ${template.defaultImage}

  `;
}
