import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="min-h-screen flex justify-center items-center bg-gray-100 px-4 sm:px-6 lg:px-8 bg-bgBlue">
        <div className="w-full max-w-md bg-white p-8 shadow-md">
          <h1 className="text-2xl font-bold mb-4 text-center">404 Not Found</h1>

          <div className="mt-6 flex justify-center">
            <Link to={`/`}>ログイン画面に戻る</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
