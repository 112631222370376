import axios from "axios";

const api = axios.create({
  baseURL:
    "https://us-central1-chatbot-app-osakagas.cloudfunctions.net/api_admin",
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const DeleteMutate = async (userIds: string[]) => {
  try {
    const response = await api.post("/users", { userIds });
    return response.data;
  } catch (error) {
    throw error;
  }
};
