import Button from "@mui/material/Button";
import { FC } from "react";
type Props = {
  content: string;
};

const AuthButton: FC<Props> = ({ content }) => {
  return (
    <Button size="large" variant="contained" fullWidth>
      {content}
    </Button>
  );
};

export default AuthButton;
