import LoadingButton from "@mui/lab/LoadingButton";
import { FC } from "react";

const AuthLoadingButton: FC = () => {
  return (
    <LoadingButton loading variant="outlined" fullWidth>
      Submit
    </LoadingButton>
  );
};

export default AuthLoadingButton;
