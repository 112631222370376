import { z } from "zod";

export const homeSchema = z.object({
  question: z
    .string({ invalid_type_error: "入力された形式が正しくありません" })
    .min(1, { message: "必須項目です" }),
});

export const postServiceSchema = z.object({
  uid: z.string().optional(),
  text: z.string(),
  accessToken: z.string(),
  sender: z.string(),
});
export const postLangChainSchema = z.object({
  accessToken: z.string(),
  question: z.string(),
});
export const deleteServiceSchema = z.object({
  uid: z.string().optional(),
  accessToken: z.string(),
});
