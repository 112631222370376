import React, { FC } from "react";

import sanitizeHtml from "sanitize-html";
type Props = {
  messages: any;
};

const Chat: FC<Props> = ({ messages }) => {

  const createMarkup = (htmlContent: string) => {
    // まず、既存のリンクを識別して保存する
    const existingLinkRegex = /<a [^>]*href=["']?([^"'>]+)["']?[^>]*>.*?<\/a>/g;
    let existingLinks = htmlContent.match(existingLinkRegex) || [];

    const urlRegex = /(https?:\/\/[^\s<)]+)/g;

    const contentWithLinks = htmlContent.replace(urlRegex, (url) => {
      if (existingLinks.some((link) => link.includes(url))) {
        return url;
      } else {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="underline">${url}</a>`;
      }
    });

    // sanitizeHtmlでHTMLコンテンツをクリーンアップ
    const sanitizedContent = sanitizeHtml(contentWithLinks, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(["br", "a"]),
      allowedAttributes: {
        a: ["href", "target", "class", "rel"],
      },
    });

    const contentWithBreaks = sanitizedContent.split("\n").join("<br />");

    return { __html: contentWithBreaks };
  };

  return (
    <div>
      {messages.map((message: any, index: any) => (
        <React.Fragment key={index}>
          <div
            className={
              message.sender === "bot"
                ? "flex bg-questionBg items-start p-4 border-b border-borderGray shadow"
                : "flex bg-white items-start p-4 border-b border-borderGray shadow"
            }
          >
            <div>
              {message.sender === "bot" ? (
                <img
                  src="chatgpt.png"
                  alt="OpenAIアイコン"
                  className="w-[52px]"
                />
              ) : (
                <img
                  src="userIcon.png"
                  alt="ユーザアイコン"
                  className="w-[52px]"
                />
              )}
            </div>
            <div
              className="flex-1 text-lg font-medium pl-5 py-3 break-all"
              dangerouslySetInnerHTML={createMarkup(message.text)}
            ></div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Chat;
