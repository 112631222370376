import { CssBaseline, AppBar, Toolbar } from '@mui/material';

export const MyAppBar = (props: any) => {
    return (
    <>
      <CssBaseline />
      <AppBar sx={{backgroundColor: "#0063b5"}}>
        <Toolbar>
          <h1 className="font-semibold">
            さすガねっと<br/>レコメンドAI支援ツール
          </h1>
          <h6 className="ml-20 text-xl font-medium">{props.title}</h6>
        </Toolbar>
      </AppBar>
    </>
  );
}
