import axios from "axios";
import { db } from "../../lib/firebase/firebase";

const backendUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_DEVELOPMENT_URL
    : process.env.REACT_APP_BACKEND_PRODUCTION_URL;

export const getFilesName = async () => {
  try {
    const snapshot = await db.collection("files").get();
    const filesData = snapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt.toDate(),
      };
    });

    return filesData;
  } catch (error) {
    console.error("Error getting documents from Firestore:", error);
    throw error;
  }
};
export const postIndexFile = async (accessToken: string, file: File) => {
  const formData = new FormData();

  formData.append("file", file);

  try {
    const response = await axios.post(
      `${backendUrl}/langchain/file`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("File upload failed:", error);
    throw error;
  }
};
export const deleteIndexFile = async (
  accessToken: string,
  fileName: string[]
) => {
  const response = await axios.post(
    `${backendUrl}/delete`,
    { fileName },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
};
export const createIndex = async (accessToken: string, fileName: string[]) => {
  const response = await axios.post(
    `${backendUrl}/create/langchain`,
    { fileName },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
};
